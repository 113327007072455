<template>
  <Modal
    close
    headerBorder
    footerBorder
    :show-modal="show"
    :loading="loading"
    @close="emit('close')"
  >
    <template #header>
      <TitleRegular neutral :title="$t('shared.label.confirm_deletion')" />
    </template>
    <template #body>
      <slot />
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('shared.button.delete')"
          color="error"
          class="flex-1"
          @click="emit('delete')"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="emit('close')"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import i18n from '@/i18n'

const $t = i18n.t

const emit = defineEmits(['close', 'delete'])
const props = defineProps({
  show: Boolean,
  loading: Boolean
})
</script>
