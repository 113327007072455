<template>
  <Modal
    close
    headerBorder
    footerBorder
    :show-modal="showBulkUpdateSegments"
    :loading="loading"
    @close="handleCancel"
  >
    <template #header>
      <TitleRegular neutral :title="getModalTitle" />
    </template>
    <template #body>
      <div class="flex flex-col gap-6 mt-6">
        <TextTiny lightNeutral>{{
          $t('customer.training_data.ai_training.update.info')
        }}</TextTiny>
        <Select :title="$t('shared.label.status')">
          <SelectSelectionCustom
            data-cy="update_status_select"
            placeholder="-"
            :disabled="deleteChecked"
            :value="
              selected.status
                ? $t(`customer.training_data.ai_training.${selected.status}`)
                : ''
            "
          >
            <SelectOptionCustom
              value="enabled"
              :selected="selected.status === 'enabled'"
              @inputChange="onStatusSelect"
            >
              <span>{{
                $t('customer.training_data.ai_training.enabled')
              }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="disabled"
              :selected="selected.status === 'disabled'"
              @inputChange="onStatusSelect"
            >
              <span>{{
                $t('customer.training_data.ai_training.disabled')
              }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
        <Select
          v-if="bulkAiUpdate"
          :title="$t('customer.lang_assets.segment.usage')"
        >
          <SelectSelectionCustom
            data-cy="update_usage_select"
            placeholder="-"
            :disabled="deleteChecked"
            :value="selected.usage ? getUsageValue : ''"
          >
            <SelectOptionCustom
              value="global"
              :selected="selected.usage === 'global'"
              @inputChange="onUsageSelect"
            >
              <span>{{ $t('customer.training_data.ai_training.global') }}</span>
            </SelectOptionCustom>
            <SelectOptionCustom
              value="team"
              :selected="selected.usage === currentAccountId"
              @inputChange="onUsageSelect"
            >
              <span>{{ capitalizeFirstLetter(currentAccountId) }}</span>
            </SelectOptionCustom>
          </SelectSelectionCustom>
        </Select>
        <div
          v-if="bulkAiUpdate"
          class="w-full grid grid-cols-[max-content_1fr]"
        >
          <CheckboxBtn
            red
            class="mt-0.5"
            :is-active="deleteChecked"
            @click="hanldeDelete"
          />
          <div class="flex flex-col gap-1">
            <TextSmall errorDark medium>{{
              $t('customer.training_data.ai_training.update.delete')
            }}</TextSmall>
            <TextTiny error>{{
              $t('customer.training_data.ai_training.update.delete.info')
            }}</TextTiny>
            <Label
              v-if="deleteChecked"
              required
              :title="
                $t('customer.training_data.ai_training.update.delete.confirm')
              "
            >
              <InputField
                id="segments_count_input"
                :placeholder="
                  $t(
                    'customer.training_data.ai_training.update.delete.confirm.placeholder'
                  )
                "
                type="number"
                @inputChange="handleSegmentsCount"
              />
            </Label>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="
            deleteChecked
              ? $t('shared.button.delete')
              : $t('shared.button.update')
          "
          :color="deleteChecked ? 'error' : 'primary'"
          :disabled="disableBtn || loading"
          class="flex-1"
          @click="handleUpdate"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="handleCancel"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import { capitalize, capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const deleteChecked = ref(false)
const segmentCount = ref(null)
const selected = ref({
  status: '',
  usage: ''
})

const isTeamAdmin = computed(() => store.getters['teams/isTeamAdmin'])
const isAdmin = computed(() => store.getters['workspace/isAdmin'])

const bulkAiUpdate = computed(() => store.state.trainingData.bulkAiUpdate)

const selectedAis = computed(() => store.state.trainingData.selectedAis)
const aiDataFilter = computed(() => store.state.trainingData.aiDataFilter)

const selectedTms = computed(() => store.state.trainingData.selectedTms)
const tmDataFilter = computed(() => store.state.trainingData.tmDataFilter)

const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)
const showBulkUpdateSegments = computed(
  () => store.state.modals.showBulkUpdateSegments
)

const filter = computed(() =>
  bulkAiUpdate.value ? aiDataFilter.value : tmDataFilter.value
)
const selectedSegments = computed(() =>
  bulkAiUpdate.value ? selectedAis.value : selectedTms.value
)

const disableBtn = computed(() => {
  if (bulkAiUpdate.value) {
    return (
      (deleteChecked.value &&
        segmentCount.value !== selectedSegments.value.length) ||
      (!deleteChecked.value &&
        (!selected.value.status || !selected.value.usage))
    )
  }
  return !selected.value.status
})

const getModalTitle = computed(() =>
  $t('customer.training_data.ai_training.update_title', {
    count: selectedSegments.value.length,
    source: capitalize(filter.value.source_language),
    target: capitalize(filter.value.target_language)
  })
)
const getUsageValue = computed(() => {
  switch (selected.value.usage) {
    case 'global':
      return $t('customer.training_data.ai_training.global')
    default:
      return capitalizeFirstLetter(currentAccountId.value)
  }
})

const bulkUpdateTmDataStatus = (payload) =>
  store.dispatch('trainingData/bulkUpdateTmDataStatus', payload)

const bulkDeleteAiData = (payload) =>
  store.dispatch('trainingData/bulkDeleteAiData', payload)
const updateAiData = (payload) =>
  store.dispatch('trainingData/updateAiData', payload)
const selectAllAi = (payload) =>
  store.dispatch('trainingData/selectAllAi', payload)

const toggleBulkUpdateSegments = () =>
  store.commit('modals/toggleBulkUpdateSegments')

function onStatusSelect(value) {
  selected.value.status = value
}
function onUsageSelect(value) {
  selected.value.usage = value
}
function hanldeDelete() {
  resetSelected()
  deleteChecked.value = !deleteChecked.value
}
function handleSegmentsCount({ value }) {
  segmentCount.value = value
}

function resetSelected() {
  selected.value = {
    status: '',
    usage: ''
  }
}
function handleCancel() {
  resetSelected()
  deleteChecked.value = false
  segmentCount.value = null
  toggleBulkUpdateSegments()
}

async function handleUpdate() {
  loading.value = true
  try {
    if (deleteChecked.value) await bulkDeleteAiData()
    else {
      if (bulkAiUpdate.value) {
        let segments = []
        selectedAis.value.forEach((ai) => {
          segments.push({
            id: ai,
            ...selected.value
          })
        })
        await updateAiData({ segments })
        selectAllAi({ isSelected: false })
      } else {
        await bulkUpdateTmDataStatus(selected.value.status)
      }
    }
    handleCancel()
  } finally {
    loading.value = false
  }
}
</script>
