<template>
  <Modal
    close
    headerBorder
    footerBorder
    :show-modal="showGenerateAIData"
    :loading="loading"
    @close="toggleGenerateAiData"
  >
    <template #header>
      <TitleRegular
        neutral
        :title="$t('customer.training_data.ai_training.generate_title')"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-8 mt-6">
        <div>
          <TextTiny lightNeutral class="mb-6">{{
            $t('customer.training_data.ai_training.generate.info')
          }}</TextTiny>
          <div class="flex flex-col gap-4">
            <Select
              :title="
                $t('customer.training_data.ai_training.generate.ai_model')
              "
            >
              <SelectSelectionCustom
                data-cy="ai_model_select"
                allowSearch
                :value="
                  selected.model === 'custom'
                    ? $t(
                        'customer.training_data.ai_training.generate.ai_model.custom'
                      )
                    : selected.model
                "
                @search="handleModelSearch"
              >
                <SelectOptionCustom
                  v-if="
                    $t(
                      'customer.training_data.ai_training.generate.ai_model.custom'
                    )
                      .toLowerCase()
                      .includes(modelSearch.toLowerCase())
                  "
                  value="custom"
                  :selected="selected.model === 'custom'"
                  @inputChange="onModelChange"
                >
                  <span>{{
                    $t(
                      'customer.training_data.ai_training.generate.ai_model.custom'
                    )
                  }}</span>
                </SelectOptionCustom>
                <SelectOptionCustom
                  v-for="(model, i) in filteredModels"
                  :key="i"
                  :value="model.type"
                  :selected="selected.model === model.type"
                  @inputChange="onModelChange"
                >
                  <span>{{ model.type }}</span>
                </SelectOptionCustom>
              </SelectSelectionCustom>
            </Select>
            <Label
              v-if="selected.model === 'custom'"
              :title="
                $t(
                  'customer.training_data.ai_training.generate.custom_ai_model'
                )
              "
            >
              <InputField
                :placeholder="
                  $t('customer.training_data.ai_training.generate.ai_model')
                "
                @inputChange="({ value }) => (customModel = value)"
            /></Label>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex items-center justify-between mb-1">
            <Label
              :title="
                $t('customer.training_data.ai_training.generate.temperature')
              "
              class="pt-2"
            />
            <InputField
              id="temperature_input"
              input-appearance-none
              :value="selected.temperature"
              :min="0"
              :max="1.2"
              :step="0.01"
              centered
              type="number"
              @inputChange="handleTemperature"
            />
          </div>
          <RangeSlider
            id="temperature_slider"
            update-key="ai_temperature"
            :min="0"
            :max="1.2"
            :step="0.01"
            :value="selected.temperature"
            @updateValue="rangeTemperature"
          />
          <div class="flex items-center justify-between">
            <TextTiny>{{ $t('shared.caas.sidebar.low') }}</TextTiny>
            <TextTiny>{{ $t('shared.caas.sidebar.high') }}</TextTiny>
          </div>
        </div>
        <Label
          :title="$t('customer.training_data.ai_training.generate.segments')"
        >
          <InputField
            id="segments_input"
            :value="selected.number_of_segments"
            :min="1"
            :max="10"
            type="number"
            @inputChange="handleSegmentsCount"
          />
        </Label>
        <div
          v-if="isTeamAdmin || isAdmin"
          class="w-full grid grid-cols-[max-content_1fr]"
        >
          <CheckboxBtn
            class="mt-0.5"
            :is-active="selected.globalUse"
            @click="handleGlobalUse"
          />
          <div class="flex flex-col gap-1">
            <TextSmall neutral medium>{{
              $t('customer.training_data.ai_training.generate.global_use')
            }}</TextSmall>
            <TextTiny lightNeutral>{{
              $t('customer.training_data.ai_training.generate.global_use.info')
            }}</TextTiny>
          </div>
        </div>
        <div>
          <div
            class="w-full flex justify-between items-center bg-neutral-50 cursor-pointer border border-neutral-80 rounded-lg p-2.5 hover:border-primary-100"
            @click="showSystemPrompt = !showSystemPrompt"
          >
            <TextSmall>
              {{
                $t(
                  'customer.training_data.ai_training.generate.global_use.system_prompt'
                )
              }}
            </TextSmall>
            <font-awesome-icon
              :icon="showSystemPrompt ? 'fa-chevron-up' : 'pen-to-square'"
              class="text-neutral-200"
            />
          </div>
          <div v-if="showSystemPrompt" class="bg-neutral-50 rounded-lg p-3">
            <Textarea
              data-cy="system_prompt"
              :placeholder="
                $t(
                  'customer.training_data.ai_training.generate.global_use.system_prompt.placeholder'
                )
              "
              white
              size="md"
              :value="selected.system_prompt"
              @inputChange="onSystemPrompt"
            >
            </Textarea>
          </div>
        </div>
        <div class="bg-neutral-50 rounded-lg p-3">
          <Textarea
            data-cy="user_prompt"
            :placeholder="
              $t(
                'customer.training_data.ai_training.generate.global_use.user_prompt'
              )
            "
            white
            size="sm"
            :value="selected.user_prompt"
            @inputChange="onUserPrompt"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('customer.training_data.ai_training.generate.btn')"
          :disabled="disableBtn"
          class="flex-1"
          @click="handleGenerate"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="toggleGenerateAiData"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import CheckboxBtn from '@/components/shared/checkbox/CheckboxBtn'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputField from '@/components/shared/input/InputField'
import Label from '@/components/shared/label/Label'
import Modal from '@/components/shared/Modal'
import Select from '@/components/shared/select/Select'
import SelectOptionCustom from '@/components/shared/select/SelectOptionCustom'
import SelectSelectionCustom from '@/components/shared/select/SelectSelectionCustom'
import RangeSlider from '@/components/shared/sliders/RangeSlider'
import Textarea from '@/components/shared/textarea/Textarea'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)

const showSystemPrompt = ref(false)
const modelSearch = ref('')
const customModel = ref('')
const selected = ref({
  model: 'gpt-4o',
  temperature: 1,
  number_of_segments: 10,
  globalUse: false,
  system_prompt: '',
  user_prompt: ''
})

const isTeamAdmin = computed(() => store.getters['teams/isTeamAdmin'])
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const openAiModels = computed(() => store.state.trainingData.openAiModels)
const showGenerateAIData = computed(() => store.state.modals.showGenerateAIData)

const disableBtn = computed(() => {
  return (
    (!selected.value.model === 'custom' && !customModel.value) ||
    !selected.value.system_prompt ||
    !selected.value.user_prompt ||
    selected.value.number_of_segments === 0 ||
    loading.value
  )
})

const filteredModels = computed(() => {
  return openAiModels.value.filter(
    (m) =>
      !modelSearch.value ||
      m.type.toLowerCase().includes(modelSearch.value.toLowerCase())
  )
})

const generateAiData = (payload) =>
  store.dispatch('trainingData/generateAiData', payload)
const toggleGenerateAiData = () => store.commit('modals/toggleGenerateAiData')

function onModelChange(model) {
  selected.value.model = model
  customModel.value = ''
}

function handleTemperature({ value }) {
  selected.value.temperature = value
}
function rangeTemperature(val) {
  selected.value.temperature = val
}

function handleSegmentsCount({ value }) {
  selected.value.number_of_segments = value
}

function handleGlobalUse() {
  selected.value.globalUse = !selected.value.globalUse
}

function onSystemPrompt(value) {
  selected.value.system_prompt = value
}
function onUserPrompt(value) {
  selected.value.user_prompt = value
}

const handleModelSearch = _.debounce(function (value) {
  modelSearch.value = value
}, 300)

async function handleGenerate() {
  loading.value = true
  try {
    const data = {
      ...selected.value,
      ...(selected.value.model === 'custom' ? { model: customModel.value } : {})
    }
    await generateAiData(data)
    toggleGenerateAiData()
  } finally {
    loading.value = false
  }
}

watch(
  () => showGenerateAIData.value,
  (show) => {
    if (!show) {
      showSystemPrompt.value = false
      customModel.value = false
      selected.value.model = 'gpt-4o'
      selected.value.temperature = 1
      selected.value.number_of_segments = 10
      selected.value.globalUse = false
      selected.value.system_prompt = ''
      selected.value.user_prompt = ''
    }
  }
)
</script>
