<template>
  <Modal
    xl
    close
    headerBorder
    footerBorder
    :show-modal="showAddTrainingSegments"
    :loading="loading"
    @close="toggleAddTrainingSegments"
  >
    <template #header>
      <TitleRegular
        neutral
        :title="$t('customer.training_data.tm_training.add_segment.title')"
      />
    </template>
    <template #body>
      <div class="flex flex-col gap-4 mt-8">
        <TextTiny lightNeutral>{{
          $t('customer.training_data.tm_training.add_segment.info')
        }}</TextTiny>
        <div class="flex-1">
          <TrainingTable
            :source="tmDataFilter.source_language"
            :target="tmDataFilter.target_language"
            :actions="false"
            :status="false"
            :isAllSelected="allNotInTrainingsSelected"
            :selectAll="selectAllNotInTraining"
            @filter="handleFilter"
          >
            <TrainingTableRow
              v-for="(segment, i) in notInTrainingData"
              :key="i"
              :segment="segment"
              :selectedSegments="selectedNotInTraining"
              :source="tmDataFilter.source_language"
              :target="tmDataFilter.target_language"
              :actions="false"
              :status="false"
              disableTarget
              disableCorrected
              :select="selectNotInTraining"
              :deselect="deselectNotInTraining"
              @update="handleUpdate"
            />
          </TrainingTable>
          <div
            v-if="notInTrainingData.length > 0"
            class="tabletLandscape:sticky tabletLandscape:-bottom-6 h-max mt-auto w-full shadow-[0px_-10px_20px_0px_rgba(0,0,0,0.03)]"
          >
            <PaginationPerPage
              :pages="notInTrainingDataPages"
              light
              tableData
              class="h-max"
              @onChange="handlePageChange"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <BtnsWrapper class="w-full flex gap-4">
        <BtnPrimary
          :title="$t('customer.training_data.tm_training.add_segment.btn')"
          :disabled="selectedNotInTraining.length === 0"
          class="flex-1"
          @click="handleAddSegments"
        />
        <BtnSecondary
          neutral
          outline
          :title="$t('shared.button.cancel')"
          class="flex-1"
          @click="toggleAddTrainingSegments"
        />
      </BtnsWrapper>
    </template>
  </Modal>
</template>
<script setup>
import TrainingTable from '@/components/languageAssets/trainingData/TrainingTable'
import TrainingTableRow from '@/components/languageAssets/trainingData/TrainingTableRow'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import PaginationPerPage from '@/components/shared/pagination/PaginationPerPage'
import i18n from '@/i18n'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)

const tmDataFilter = computed(() => store.state.trainingData.tmDataFilter)
const selectedNotInTraining = computed(
  () => store.state.trainingData.selectedNotInTraining
)
const allNotInTrainingsSelected = computed(
  () => store.state.trainingData.allNotInTrainingsSelected
)
const notInTrainingData = computed(
  () => store.state.trainingData.notInTrainingData
)
const notInTrainingDataPages = computed(
  () => store.state.trainingData.notInTrainingDataPages
)

const showAddTrainingSegments = computed(
  () => store.state.modals.showAddTrainingSegments
)

const toggleAddTrainingSegments = () =>
  store.commit('modals/toggleAddTrainingSegments')

const addTmData = (payload) => store.dispatch('trainingData/addTmData', payload)
const getTmData = (payload) => store.dispatch('trainingData/getTmData', payload)
const updateTmData = (payload) =>
  store.dispatch('trainingData/updateTmData', payload)
const setNotInTrainingFilter = (payload) =>
  store.commit('trainingData/setNotInTrainingFilter', payload)

const selectAllNotInTraining = (payload) =>
  store.dispatch('trainingData/selectAllNotInTraining', payload)
const selectNotInTraining = (payload) =>
  store.commit('trainingData/selectNotInTraining', payload)
const deselectNotInTraining = (payload) =>
  store.commit('trainingData/deselectNotInTraining', payload)

async function handleUpdate(data) {
  await updateTmData({ ...data, training_data: false })
}

async function handleFilter(filter) {
  loading.value = true
  try {
    setNotInTrainingFilter(filter)
    await getTmData({ training_data: false })
  } finally {
    loading.value = false
  }
}

async function handleAddSegments() {
  loading.value = true
  try {
    await addTmData()
    toggleAddTrainingSegments()
  } finally {
    loading.value = false
  }
}

async function handlePageChange(num) {
  selectAllNotInTraining({ isSelected: false })
  loading.value = true
  try {
    await getTmData({ page: num, training_data: false })
  } finally {
    loading.value = false
  }
}
</script>
